'use strict';

import $ from 'jquery';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
    faBookOpen,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faClock,
    faEnvelope,
    faRss,
    faTag,
    faAngleRight,
    faUnlock,
    faMapMarker,
    faPhone,
    faCookie,
    faCookieBite,
    faBuilding,
    faUpRightFromSquare,
    faChartLine,
    faShareFromSquare,
    faArrowRight,
    faSquareUpRight
} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebook,
    faFacebookF,
    faGithub,
    faGitlab,
    faInstagram,
    faKeybase,
    faLinkedin,
    faLinkedinIn,
    faMastodon,
    faMedium,
    faPinterest,
    faReddit,
    faRedditAlien,
    faStackOverflow,
    faXTwitter,
    faWeibo,
    faXing
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faBookOpen,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faClock,
    faEnvelope,
    faFacebook,
    faFacebookF,
    faGithub,
    faGitlab,
    faInstagram,
    faKeybase,
    faLinkedin,
    faLinkedinIn,
    faMastodon,
    faMedium,
    faPinterest,
    faReddit,
    faRedditAlien,
    faRss,
    faStackOverflow,
    faTag,
    faXTwitter,
    faWeibo,
    faXing,
    faAngleRight,
    faUnlock,
    faMapMarker,
    faPhone,
    faCookie,
    faCookieBite,
    faBuilding,
    faUpRightFromSquare,
    faChartLine,
    faShareFromSquare,
    faArrowRight,
    faSquareUpRight
);

export default {
    loadFontAwesome: () => {
        dom.watch();
    },
    bootstrapify: () => {
        $('.content blockquote').addClass('blockquote');
        $('.content table').addClass('table');
        $('.content table').wrap('<div class="table-responsive" />');
        $('.content table thead').addClass('thead-dark');
        $('.content pre').wrap('<figure class="highlight" />');
        $('.content figure > img').addClass('img-fluid');
    },
    lazyload: async () => {
        const { default: LazyLoad } = await import(/* webpackChunkName: "lazyload" */ 'vanilla-lazyload');
        new LazyLoad({
            thresholds: "40px 10%",
            load_delay: 100,
        });
    },
    navbarFade: () => {
        let $position = $(window).scrollTop();

        $(window).scroll(() => {
            const $scroll = $(window).scrollTop();
            const $navbarHeight = $('#navbar-main-menu.fixed-top').outerHeight();

            $scroll > $position ? $('#navbar-main-menu.fixed-top').css('top', -$navbarHeight) :
                $('#navbar-main-menu.fixed-top').css('top', 0);

            if ($scroll <= 0) {
              $('#navbar-main-menu.fixed-top').css('top', 0);
            }

            $position = $scroll;
        });
    },
    lightbox: async () => {
        const { default: ekkoLightbox } = await import(/* webpackChunkName: "ekkoLightbox" */ 'ekko-lightbox');
        $('[data-toggle="lightbox"]').click(function(e) {
            e.preventDefault();
            $(this).ekkoLightbox();
        });
    },
    syntaxHighlight: () => {
        if (!window.Prism) {
          return;
        }

        Prism.highlightAll();

        $('pre:has(> code[class*=language-])').removeAttr('style');

        const element = $('pre:has(> code:not([class*=language-]))');

        element.addClass('language-none');
        $('> code', element).addClass('language-none');
    }
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString()+";";
  https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#samesitesamesite-value
  var samesite = "SameSite=None; Secure;";
  document.cookie = cname + "=" + cvalue + ";" + expires + samesite + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie(cname, cvalue) {
  var cookie = getCookie(cname);
  if (cookie != "") {
  } else if (cname == "cookie-consent") {
    setCookie(cname, cvalue, 30);
  } else if (cname == "ga-disable-G-384T8XM9W9")
    setCookie(cname, cvalue, 30);
} ;

function hideCookieModal(element) {
  const el = document.querySelector(element);
  el.classList.remove('d-flex');
  el.classList.add('d-none');
}
function showCookieModal(element) {
  const el = document.querySelector(element);
  el.classList.remove('d-none');
  el.classList.add('d-flex');
}

$('document').ready(function(){

  /* if there is no Google OptOut cookie set,
   * set to OptOut by default */
  var googleOptOutCookie = getCookie("ga-disable-G-384T8XM9W9");
  if (googleOptOutCookie == "") {
    setCookie("ga-disable-G-384T8XM9W9", "true", 30);
  }

  /* on click on accept all cookies, set
   * cookie cookie-consent to accept
   * cookie Google OptOut to false
   * and hide the cookie-consent modal*/
  document.getElementById("cookie.okay").onclick = function () {
    checkCookie("cookie-consent", "accept");
    setCookie("ga-disable-G-384T8XM9W9", "false", 30);
    const consent = getCookie("cookie-consent")
    if (consent != "" && consent != null) {
      hideCookieModal("#cookie-consent");
    }
  };
  /* on click on only_technical, set
   * cookie cookie-consent to technical
   * cookie Google OptOut to true
   * and hide the cookie-consent modal*/
  document.getElementById("cookie.notokay").onclick = function () {
    checkCookie("ga-disable-G-384T8XM9W9", "true");
    checkCookie("cookie-consent", "technical");
    const noconsent = getCookie("ga-disable-G-384T8XM9W9")
    if (noconsent != "" && noconsent != null) {
      hideCookieModal("#cookie-consent");
    }
  };

  /* do not show the cookie consent modal, if the cookie "cookie-consent" is set */
  const cookieconsent = document.querySelector('#cookie-consent');
  const consent = getCookie("cookie-consent");
  const noconsent = getCookie("ga-disable-G-384T8XM9W9");
  if (cookieconsent) {
    if ((consent != "" && consent != null))
    {
      hideCookieModal("#cookie-consent");
    } else {
      showCookieModal("#cookie-consent");
    }
  }
});
